"use strict";

exports.__esModule = true;
exports.universal = exports.tag = exports.string = exports.selector = exports.root = exports.pseudo = exports.nesting = exports.id = exports.comment = exports.combinator = exports.className = exports.attribute = void 0;

var _attribute = _interopRequireDefault(require("./attribute"));

var _className = _interopRequireDefault(require("./className"));

var _combinator = _interopRequireDefault(require("./combinator"));

var _comment = _interopRequireDefault(require("./comment"));

var _id = _interopRequireDefault(require("./id"));

var _nesting = _interopRequireDefault(require("./nesting"));

var _pseudo = _interopRequireDefault(require("./pseudo"));

var _root = _interopRequireDefault(require("./root"));

var _selector = _interopRequireDefault(require("./selector"));

var _string = _interopRequireDefault(require("./string"));

var _tag = _interopRequireDefault(require("./tag"));

var _universal = _interopRequireDefault(require("./universal"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var attribute = function attribute(opts) {
  return new _attribute["default"](opts);
};

exports.attribute = attribute;

var className = function className(opts) {
  return new _className["default"](opts);
};

exports.className = className;

var combinator = function combinator(opts) {
  return new _combinator["default"](opts);
};

exports.combinator = combinator;

var comment = function comment(opts) {
  return new _comment["default"](opts);
};

exports.comment = comment;

var id = function id(opts) {
  return new _id["default"](opts);
};

exports.id = id;

var nesting = function nesting(opts) {
  return new _nesting["default"](opts);
};

exports.nesting = nesting;

var pseudo = function pseudo(opts) {
  return new _pseudo["default"](opts);
};

exports.pseudo = pseudo;

var root = function root(opts) {
  return new _root["default"](opts);
};

exports.root = root;

var selector = function selector(opts) {
  return new _selector["default"](opts);
};

exports.selector = selector;

var string = function string(opts) {
  return new _string["default"](opts);
};

exports.string = string;

var tag = function tag(opts) {
  return new _tag["default"](opts);
};

exports.tag = tag;

var universal = function universal(opts) {
  return new _universal["default"](opts);
};

exports.universal = universal;